import { useTranslation } from "react-i18next";
import { gtmTracking } from "~/utils";
import { Footer as FooterUI } from "@capterra/vendor-ui-components-footer";
import { SelectField } from "~/components/vendorUI";

import {
  Link,
  useLoaderData,
  useRouteLoaderData,
  useSubmit,
} from "react-router";
import type { loader } from "app/routes/__vendor-portal";
import type { LinkTypeOutput } from "app/types/navigation";

const currentYear = new Date().getFullYear();

const FooterLink = ({ name, path, external }: LinkTypeOutput) => {
  return (
    <FooterUI.Link asChild>
      {external ? (
        <a href={path} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      ) : (
        <Link to={path}>{name}</Link>
      )}
    </FooterUI.Link>
  );
};

export function Footer() {
  const { t, i18n } = useTranslation();
  const { session } = useLoaderData<typeof loader>();
  const data = useRouteLoaderData<{ locale: string }>("root");
  const submit = useSubmit();

  /* Add help center separately, because we want to show it in footer,
  but in the first column which belongs to navbar links */
  const footerColumns =
    "userId" in session
      ? [
          [
            ...session.navConfig.navbar,
            {
              name: t("NAV_LABEL_HELP-CENTER"),
              path: "/help-center",
            },
          ],
          session.navConfig.footer,
        ]
      : [session.navConfig.footer];

  return (
    <FooterUI>
      <FooterUI.Columns columns={5}>
        {footerColumns.map(
          (column, idx) =>
            Array.isArray(column) &&
            column.length > 0 && (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <FooterUI.Column key={idx}>
                {column.map((link, i) => (
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  <FooterLink key={`${i}-path`} {...link} />
                ))}
              </FooterUI.Column>
            ),
        )}

        <FooterUI.Column>
          <SelectField<string>
            id="languageSwitcher"
            label={t("LANGUAGE-SWITCHER_CHANGE-LANGUAGE_LABEL")}
            renderValue={(lang) => supportedTranslations.get(lang)!}
            defaultValue={data?.locale || i18n.language}
            maxHeight={150}
            onChange={(locale) => {
              submit(
                {
                  locale,
                  intent: "changeLanguage",
                },
                { method: "POST", preventScrollReset: true },
              );
              gtmTracking("language_change", {
                old_value: data?.locale || i18n.language,
                new_value: locale,
              });
            }}
          >
            {[...supportedTranslations.entries()].map(([key, value]) => (
              <SelectField.Option
                key={key}
                value={key}
                renderLabel={() => value}
              />
            ))}
          </SelectField>
        </FooterUI.Column>
      </FooterUI.Columns>
      <FooterUI.Social>
        <FooterUI.SocialLink
          href="https://www.linkedin.com/showcase/gartner-digital-markets/"
          name="CircleLinkedin"
        />
        <FooterUI.SocialLink
          href="https://twitter.com/gartner_dm?lang=en"
          name="CircleX"
        />
      </FooterUI.Social>
      <FooterUI.Copyright>
        {t("FOOTER_LABEL_COPYRIGHT", { currentYear })}
      </FooterUI.Copyright>
    </FooterUI>
  );
}

const languageZone = {
  "de-DE": "Deutsch",
  "en-US": "English",
  "es-ES": "Español",
  "fr-FR": "Français",
  "it-IT": "Italiano",
  "ja-JP": "日本語",
  "nl-NL": "Nederlands",
  "pt-BR": "Português",
};

const supportedTranslations = new Map(Object.entries(languageZone));
